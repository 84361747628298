<template>
  <div>
    <div class="repo-analyzer-report__subtitle">
      Por proyecto
    </div>
    <div
      class="repo-analyzer-report__subsection"
      v-if="dataLoaded()"
    >
      <table class="repo-analyzer-report-table">
        <tr>
          <th>Proyecto</th>
          <th
            v-for="powerType in data.powerTypes"
            :key="powerType.id"
          >
            {{ powerType.title }}
          </th>
        </tr>
        <tr
          v-for="project in data.projects"
          :key="project.id"
        >
          <td>{{ project.repoName }}</td>
          <td
            v-for="powerType in data.powerTypes"
            :key="powerType.id"
          >
            <div
              :class="isTypeUsed(project, powerType.id) ? 'repo-analyzer-true-value' : 'repo-analyzer-false-value'"
            />
          </td>
        </tr>
      </table>
    </div>
    <report_loader v-if="!dataLoaded()" />
    <div class="repo-analyzer-report__subtitle">
      Por tipo
    </div>
    <div
      class="repo-analyzer-report__subsection"
      v-if="dataLoaded()"
    >
      <div
        v-for="chart in data.powerTypesUsageCharts"
        :key="chart.title"
        class="repo-analyzer-report-pie"
      >
        <div class="repo-analyzer-report-pie__title">
          {{ chart.title }}
        </div>
        <div class="repo-analyzer-report-pie__chart">
          <Pie
            :data="chart.chartdata"
            :options="chart.options"
          />
        </div>
      </div>
    </div>
    <report_loader v-if="!dataLoaded()" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Pie } from 'vue-chartjs';
import getReportData from '../../../api/repo-analyzer/projects-report';

export default defineComponent({
  components: {
    Pie,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    isTypeUsed(project, powerType) {
      try {
        return !!project.info.powerTypesExtractor[powerType];
      } catch (error) {
        return false;
      }
    },
    dataLoaded() {
      return !!this.data;
    },
  },
  async mounted() {
    this.data = await getReportData(['power_types_extractor'], ['power_types_usage_charts', 'power_types']);
  },
});
</script>

import axios from 'axios';

export default function getReportData(projectInfoAttrs, sections = []) {
  const queryString = [];

  projectInfoAttrs.forEach((attr) => {
    queryString.push(`project_info_attributes[]=${attr}`);
  });

  sections.forEach((section) => {
    queryString.push(`sections[]=${section}`);
  });

  const nestUrl = process.env.NEST_URL || 'https://nest.platan.us';

  return axios
    .get(`${nestUrl}/repo_analyzer_projects/project_data?${queryString.join('&')}`)
    .then((response) => response.data);
}

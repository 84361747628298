import 'jquery-ui/external/jquery-1.8.3/jquery.js';
import 'jquery-ui/dist/jquery-ui.min.js';
import '@activeadmin/activeadmin';
import 'activeadmin_addons';
import '@fortawesome/fontawesome-free/css/all.css';
import 'arctic_admin';

import { createApp } from 'vue';
import { Chart, registerables } from 'chart.js';

import InlineSvg from '../components/inline-svg.vue';
import PieChartComponent from '../components/charts/pie-chart.vue';
// Devs survey engine
import MultipleChoiceQuestionComponent from '../components/devs-survey/multiple-choice-question-component.vue';
import CheckboxQuestionComponent from '../components/devs-survey/checkbox-question-component.vue';
import TextQuestionComponent from '../components/devs-survey/text-question-component.vue';
import GridQuestionComponent from '../components/devs-survey/grid-question-component.vue';
import QuestionsForm from '../components/devs-survey/questions-form.vue';
// Repo analyzer engine
import RepoAnalyzerReport from '../components/repo-analyzer/report.vue';
import ReportLoader from '../components/repo-analyzer/report-loader.vue';
import ProjectVersionsSection from '../components/repo-analyzer/sections/project-versions-section.vue';
import PowerTypesSection from '../components/repo-analyzer/sections/power-types-section.vue';
import CircleciSection from '../components/repo-analyzer/sections/circleci-section.vue';
import GithubSection from '../components/repo-analyzer/sections/github-section.vue';
import TestingSection from '../components/repo-analyzer/sections/testing-section.vue';
import GithubContributors from '../components/repo-analyzer/github-contributors.vue';

Chart.register(...registerables);

function onLoad() {
  if (document.getElementById('vue-wrapper') !== null) {
    const app = createApp();
    app.component('inline-svg', InlineSvg);
    app.component('pie_chart_component', PieChartComponent);
    // Devs survey engine
    app.component('multiple_choice_question_component', MultipleChoiceQuestionComponent);
    app.component('checkbox_question_component', CheckboxQuestionComponent);
    app.component('text_question_component', TextQuestionComponent);
    app.component('grid_question_component', GridQuestionComponent);
    app.component('questions_form', QuestionsForm);
    // Repo analyzer engine
    app.component('repo_analyzer_report_component', RepoAnalyzerReport);
    app.component('report_loader', ReportLoader);
    app.component('project_versions_section_component', ProjectVersionsSection);
    app.component('power_types_section_component', PowerTypesSection);
    app.component('circleci_section_component', CircleciSection);
    app.component('github_section_component', GithubSection);
    app.component('testing_section_component', TestingSection);
    app.component('github_contributors', GithubContributors);

    // Avoid using '#wrapper' as the mount point, as that includes the entire admin page,
    // which could be used for Client-Side Template Injection (CSTI) attacks. Limit the
    // mount point to specific areas where you need Vue components.

    // DO NOT mount Vue in elements that contain user input rendered by
    // ActiveAdmin.
    // By default ActiveAdmin doesn't escape {{ }} in user input, so it's
    // possible to inject arbitrary JavaScript code into the page.
    app.mount('#vue-wrapper');
  }

  return null;
}

document.addEventListener('DOMContentLoaded', onLoad, { once: true });

<script lang="ts">
import { Bar } from 'vue-chartjs';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Bar,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
});
</script>

<template>
  <div>
    <div
      v-for="(chart, index) in data.chartsdata"
      :key="index"
    >
      <h3
        v-if="data.chartsdata.length > 1"
        class="survey-report-section__category"
      >
        {{ chart.title }}
      </h3>
      <div class="survey-report-section__body">
        <Bar
          :data="data.chartdata"
          :options="data.options"
        />
      </div>
    </div>
  </div>
</template>

<template>
  <div class="survey-report-section__body">
    <Pie
      :data="data.chartdata"
      :options="data.options"
    />
  </div>
</template>

<script lang="ts">
import { Pie } from 'vue-chartjs';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Pie,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
});
</script>

<template>
  <div class="survey-questions-list">
    <div
      v-for="(question, questionIdx) in normalizedQuestions"
      :key="questionIdx"
      class="survey-question-card"
    >
      <div
        class="survey-question-card__title"
      >
        {{ questionIdx + 1 }} - {{ question.title }}
      </div>
      <ol v-if="question.choices && question.choices.length > 0">
        <li
          v-for="(choice, choiceIdx) in question.choices"
          :key="choiceIdx"
        >
          {{ choice }}
        </li>
      </ol>
    </div>
    <div
      class="survey-question-card"
    >
      <div
        v-if="defined && questionsNotDefined"
        class="survey-question-card__title survey-question-card__title--warning"
      >
        Ten en cuenta que ya se definieron preguntas para la encuesta. Si agregas nuevas, se añadirán al final.
      </div>
    </div>
    <div class="survey-question-form">
      <div class="survey-question-form__title">
        Nueva pregunta
      </div>
      <form
        action="#"
        @submit.prevent="addQuestion"
      >
        <ol
          class="survey-question-form__items"
        >
          <li
            class="survey-question-form__item"
          >
            <label
              class="survey-question-form__label"
              for="type"
            >
              Tipo:
            </label>
            <select
              name="type"
              class="default-select survey-question-form__input"
              v-model="formQuestion.type"
            >
              <option
                v-for="option in questionTypes"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </li>
          <li
            class="survey-question-form__item"
          >
            <label
              class="survey-question-form__label"
              for="title"
            >
              Texto:
            </label>
            <textarea
              type="text"
              name="title"
              class="survey-question-form__input"
              v-model="formQuestion.title"
            />
          </li>
          <li
            class="survey-question-form__item"
            v-if="hasChoices"
          >
            <label
              class="survey-question-form__label"
              for="title"
            >
              Opciones:
            </label>
            <div
              class="survey-question-form__choices"
            >
              <ol
                class="survey-question-form__choices-list"
              >
                <li
                  v-for="(formQuestionChoice, formQuestionChoiceIdx) in formQuestion.choices"
                  :key="formQuestionChoiceIdx"
                >
                  {{ formQuestionChoiceIdx + 1 }}. {{ formQuestionChoice }}
                </li>
              </ol>
              <input
                v-model="formChoice.title"
                @keyup="addChoice"
                @keydown.enter.prevent
                class="survey-question-form__input"
                placeholder="Enter para agregar..."
              />
            </div>
          </li>
          <li
            class="survey-question-form__item"
          >
            <label
              class="survey-question-form__label"
            />
            <button
              class="survey-question-form__button"
              :disabled="isFormInvalid"
            >
              Agregar pregunta
            </button>
          </li>
        </ol>
      </form>
    </div>
    <div
      class="survey-question-actions"
    >
      <button
        class="survey-question-actions__button"
        :disabled="questionsNotDefined || this.savingForm"
        @click="deleteLastQuestion"
      >
        Borrar última pregunta
      </button>
      <button
        class="survey-question-actions__button"
        :disabled="questionsNotDefined || this.savingForm"
        @click="saveQuestions"
      >
        Guardar
      </button>
      <div
        class="survey-question-loader"
        v-if="savingForm"
      >
        <inline-svg name="three-dots-loader" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as questionsApi from "../../api/devs-survey/questions";

const ENTER_KEY = 13;
const MIN_REQUIRED_CHOICES = 2;

export default defineComponent({
  props: {
    defined: {
      type: Boolean,
      default: false,
    },
    questions: {
      type: Array,
      default() {
        return [];
      },
    },
    surveyId: {
      type: Number,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      normalizedQuestions: this.questions,
      formQuestion: {
        type: 'TEXT',
        title: '',
        choices: [],
      },
      formChoice: {
        title: '',
      },
      savingForm: false,
      questionTypes: [
        {
          label: 'Pregunta texto (textarea)',
          value: 'TEXT',
        },
        {
          label: 'Pregunta opción excluyente (radio button)',
          value: 'MULTIPLE_CHOICE',
        },
        {
          label: 'Pregunta opción múltiple (checkbox)',
          value: 'CHECKBOX',
        },
      ],
    };
  },
  computed: {
    hasChoices() {
      switch (this.formQuestion.type) {
      case 'MULTIPLE_CHOICE':
      case 'CHECKBOX':
        return true;
      default:
        return false;
      }
    },
    isFormInvalid() {
      if (this.formQuestion.title === '') {
        return true;
      }

      if (this.hasChoices && this.formQuestion.choices.length < MIN_REQUIRED_CHOICES) {
        return true;
      }

      return false;
    },
    questionsNotDefined() {
      return this.normalizedQuestions.length === 0;
    },
  },
  methods: {
    addQuestion() {
      const question = { ...this.formQuestion };
      this.normalizedQuestions.push(question);
      this.formQuestion.title = '';
      this.formQuestion.choices = [];
    },
    addChoice(e) {
      if (e.keyCode === ENTER_KEY) {
        this.formQuestion.choices.push(this.formChoice.title);
        this.formChoice.title = '';
      }
    },
    saveQuestions() {
      this.savingForm = true;
      questionsApi.create(this.surveyId, this.normalizedQuestions).then(() => {
        window.location.reload();
      });
    },
    deleteLastQuestion() {
      this.normalizedQuestions.pop();
    },
  },
});
</script>

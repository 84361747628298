<template>
  <Pie
    :data="chartdata"
    :options="options"
  />
</template>

<script lang="ts">
import { Pie } from 'vue-chartjs';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    Pie,
  },
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
});
</script>

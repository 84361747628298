<template>
  <div>
    <div class="repo-analyzer-report__subtitle">
      Code coverage por proyecto
    </div>
    <div
      class="repo-analyzer-report__subsection"
      v-if="dataLoaded()"
    >
      <table class="repo-analyzer-report-table">
        <tr>
          <th>Proyecto</th>
          <th
            v-for="coverageKey in getCoverageKeys()"
            :key="coverageKey"
          >
            {{ coverageKey }}
          </th>
        </tr>
        <tr
          v-for="project in data.projects"
          :key="project.id"
        >
          <td>{{ project.repoName }}</td>
          <td
            v-for="coverageKey in getCoverageKeys()"
            :key="coverageKey"
            :class="'repo-analyzer-semaphore-' + getCoveragePercentageClass(project, coverageKey)"
          >
            {{ getCoveragePercentageText(project, coverageKey) }}
          </td>
        </tr>
      </table>
    </div>
    <report_loader v-if="!dataLoaded()" />
    <div class="repo-analyzer-report__subtitle">
      Cantidad de tests por proyecto
    </div>
    <div
      class="repo-analyzer-report__subsection"
      v-if="dataLoaded()"
    >
      <table class="repo-analyzer-report-table">
        <tr>
          <th>Proyecto</th>
          <th>Swagger</th>
          <th>Sistema</th>
          <th>JS</th>
        </tr>
        <tr
          v-for="project in data.projects"
          :key="project.id"
        >
          <td>{{ project.repoName }}</td>
          <td>{{ getInfoValue(project, 'swaggerTestsCount') }}</td>
          <td>{{ getInfoValue(project, 'systemTestsCount') }}</td>
          <td>{{ getInfoValue(project, 'jestTestsCount') }}</td>
        </tr>
      </table>
    </div>
    <report_loader v-if="!dataLoaded()" />
  </div>
</template>

<script lang="ts">
/* eslint-disable no-magic-numbers */
import Vue2Filters from 'vue2-filters';
import getReportData from '../../../api/repo-analyzer/projects-report';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [Vue2Filters.mixin],
  data() {
    return {
      data: null,
    };
  },
  methods: {
    getCoverageKeys() {
      try {
        return Object.keys(this.data.projects[0].info.testsInfoExtractor.simplecovCoverage);
      } catch (error) {
        return [];
      }
    },
    getInfoValue(project, key) {
      try {
        return project.info.testsInfoExtractor[key].toString().replace(/_/g, ' ');
      } catch (error) {
        return '-';
      }
    },
    getCoveragePercentageClass(project, coverageKey) {
      const percentage = this.getCoverageValue(project, coverageKey);

      if (percentage === null) {
        return 'undefined';
      } else if (percentage >= 80) {
        return 'good';
      } else if (percentage <= 30) {
        return 'bad';
      }

      return 'average';
    },
    getCoveragePercentageText(project, coverageKey) {
      const percentage = this.getCoverageValue(project, coverageKey);

      if (percentage === null) {
        return '-';
      }

      return `${percentage} %`;
    },
    getCoverageValue(project, key) {
      try {
        return project.info.testsInfoExtractor.simplecovCoverage[key];
      } catch (error) {
        return 0;
      }
    },
    dataLoaded() {
      return !!this.data;
    },
  },
  async mounted() {
    this.data = await getReportData(['tests_info_extractor']);
  },
});
</script>

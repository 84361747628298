<template>
  <div class="github-contibutors">
    <img
      v-for="contributor in contributors"
      :key="contributor.login"
      :title="contributor.login"
      :src="contributor.avatarUrl"
      class="github-contibutors__img"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    contributors: {
      type: Array,
      default: null,
    },
  },
});
</script>

<template>
  <div>
    <div class="repo-analyzer-report__subtitle">
      Por proyecto
    </div>
    <div
      class="repo-analyzer-report__subsection"
      v-if="dataLoaded()"
    >
      <table class="repo-analyzer-report-table">
        <tr>
          <th>Proyecto</th>
          <th
            v-for="version in data.projectVersionKeys"
            :key="version.id"
          >
            {{ version.title }}
          </th>
        </tr>
        <tr
          v-for="project in data.projects"
          :key="project.id"
        >
          <td>{{ project.repoName }}</td>
          <td
            v-for="version in data.projectVersionKeys"
            :key="version.id"
          >
            {{ getVersionValue(project, version.id) }}
          </td>
        </tr>
      </table>
    </div>
    <report_loader v-if="!dataLoaded()" />
    <div class="repo-analyzer-report__subtitle">
      Por número de versión
    </div>
    <div
      v-if="dataLoaded()"
      class="repo-analyzer-report__subsection"
    >
      <div
        v-for="chart in data.versionsCharts"
        :key="chart.title"
        class="repo-analyzer-report-pie"
      >
        <div class="repo-analyzer-report-pie__title">
          {{ chart.title }}
        </div>
        <div class="repo-analyzer-report-pie__chart">
          <Pie
            :data="chart.chartdata"
            :options="chart.options"
          />
        </div>
      </div>
    </div>
    <report_loader v-if="!dataLoaded()" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Pie } from 'vue-chartjs';
import getReportData from '../../../api/repo-analyzer/projects-report';

export default defineComponent({
  components: {
    Pie,
  },
  data() {
    return {
      data: null,
    };
  },
  methods: {
    getVersionValue(project, versionkey) {
      try {
        const value = project.info.projectVersionsExtractor[versionkey];

        if (!!value) {
          return value.replace(/_/g, ' ');
        }

        return '-';
      } catch (error) {
        return '-';
      }
    },
    dataLoaded() {
      return !!this.data;
    },
  },
  async mounted() {
    this.data = await getReportData(['project_versions_extractor'], ['project_version_keys', 'versions_charts']);
  },
});
</script>

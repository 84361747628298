<template>
  <div>
    <div class="repo-analyzer-report__subtitle">
      Por proyecto
    </div>
    <div class="repo-analyzer-report__subsection">
      <table
        v-if="dataLoaded()"
        class="repo-analyzer-report-table"
      >
        <tr>
          <th>Proyecto</th>
          <th>Último commit</th>
          <th>Últimos colaboradores</th>
        </tr>
        <tr
          v-for="project in orderBy(data.projects, 'info.githubExtractor.lastCommitDate', -1)"
          :key="project.id"
        >
          <td>
            <a
              :href="project.reportLink"
              target="_blank"
            >
              {{ project.repoName }}
            </a>
          </td>
          <td>{{ getInfoValue(project, 'lastCommitDate') }}</td>
          <td>
            <github_contributors :contributors="project.info.githubExtractor.lastContributors" />
          </td>
        </tr>
      </table>
      <report_loader v-if="!dataLoaded()" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue2Filters from 'vue2-filters';
import getReportData from '../../../api/repo-analyzer/projects-report';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [Vue2Filters.mixin],
  data() {
    return {
      data: null,
    };
  },
  methods: {
    getInfoValue(project, key) {
      try {
        return project.info.githubExtractor[key].replace(/_/g, ' ');
      } catch (error) {
        return '-';
      }
    },
    dataLoaded() {
      return !!this.data;
    },
  },
  async mounted() {
    this.data = await getReportData(['github_extractor']);
  },
});
</script>

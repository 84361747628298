<template>
  <div class="repo-analyzer-report">
    <div class="repo-analyzer-report__sidebar">
      <div
        v-for="sidebarLink in sidebarLinks"
        :key="sidebarLink.id"
        :class="{ 'repo-analyzer-report__sidebar-btn--active': isSidebarlinkSelected(sidebarLink) }"
        class="repo-analyzer-report__sidebar-btn"
        @click="setSelectedNavbarLink(sidebarLink)"
      >
        {{ sidebarLink.label }}
      </div>
    </div>
    <div class="repo-analyzer-report__content">
      <div class="repo-analyzer-report__section">
        <github_section_component
          v-if="selectedSidebarLink === 'github_section'"
        />
        <project_versions_section_component
          v-if="selectedSidebarLink === 'project_versions_section'"
        />
        <testing_section_component
          v-if="selectedSidebarLink === 'testing_section'"
        />
        <power_types_section_component
          v-if="selectedSidebarLink === 'power_types_section'"
        />
        <circleci_section_component
          v-if="selectedSidebarLink === 'circleci_section'"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      selectedSidebarLink: 'github_section',
    };
  },
  computed: {
    sidebarLinks() {
      return [
        { id: 'github_section', label: 'Info Proyectos' },
        { id: 'project_versions_section', label: 'Dependencias' },
        { id: 'testing_section', label: 'Testing' },
        { id: 'power_types_section', label: 'Uso de Power Types' },
        { id: 'circleci_section', label: 'Configuraciones de CI' },
      ];
    },
  },
  methods: {
    isSidebarlinkSelected(sidebarLink) {
      return sidebarLink.id === this.selectedSidebarLink;
    },
    setSelectedNavbarLink(sidebarLink) {
      this.selectedSidebarLink = sidebarLink.id;
    },
  },
});
</script>

import { api } from '../index';

function create(surveyId: number, questions: []) {
  const nestUrl = process.env.NEST_URL || 'https://nest.platan.us';
  const path = `${nestUrl}/devs_survey_surveys/${surveyId}/questions`;

  return api({
    method: 'post',
    url: path,
    data: {
      questions,
    },
  }).then(response => response.data);
}

export { create };
